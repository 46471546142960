import Link from 'next/link';
import React from 'react';
import { useAccount } from 'wagmi';
import { getTokenName } from '../../helpers/getChainName';
import { getCollectionPath } from '../../helpers/path';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ChainIcon } from '../Icon';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import CollectionBadges, { WatchListButton } from './collection_badges';
import { Tooltip } from '@mui/material';

export const SquareBox = ({ children, width = 100 }) => {
  return (
    <div
      className={'mx-auto w-full px-3'}
      style={{
        width: `${width}%`,
      }}
    >
      <div className='relative w-full  pt-[100%]'>
        <div className='absolute left-0 top-0 h-full w-full'>{children}</div>
      </div>
    </div>
  );
};

const CollectionRow = ({ item, index }) => {
  const {
    address,
    name,
    profilePhotoPath,
    pathName,
    creatorAddress,
    collectionmetrics,
    chain,
    itemCount: totalItems,
    isDNFT,
    isVerified,
  } = item;

  const { floorPrice, volumeTraded, ownerCount, itemCount } = collectionmetrics || {};
  const floor = floorPrice;
  const volume = volumeTraded;

  const account = useAccount();

  return (
    <Link href={getCollectionPath(chain, pathName)}>
      <a>
        <div
          className='grid cursor-pointer grid-cols-7 border-b border-jacarta-100 py-3 hover:bg-jacarta-100 dark:border-jacarta-600 dark:hover:bg-jacarta-800 md:grid-cols-12'
          role='row'
        >
          <div className='col-span-1 py-3 px-4 text-center' role='columnheader'>
            <span className='flex h-full w-full items-center justify-center overflow-hidden text-ellipsis font-display text-jacarta-700 dark:text-jacarta-100'>
              {index + 1}
            </span>
          </div>
          <div className='col-span-3 cursor-pointer py-3 px-0 md:col-span-6' role='columnheader'>
            <div className='flex w-full items-center overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              <div className='mx-auto w-[80px]'>
                <SquareBox width={100}>
                  <ImageFixedAO
                    image={getCdnUrl(profilePhotoPath, 'thumbnail', item?.updatedAt)}
                    bottomRounded
                    resolution={262}
                    alt={name}
                    className=' !rounded-t-md !rounded-b-md '
                  />
                </SquareBox>
              </div>
              <div className='flex w-full items-center text-left font-display text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent'>
                {name}
                <div className='ml-3 mr-10'>
                  <CollectionBadges isVerified={isVerified} isDNFT={isDNFT} watchListDisabled />
                </div>

                {creatorAddress === account?.address && (
                  <span
                    className='hover:text-accent-hover dark:text-accent-hover mr-5 text-accent dark:hover:text-accent'
                    style={{ position: 'relative', marginLeft: 'auto' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Link href={`/collection/edit/${address}`}>
                      <a href={`/collection/edit/${address}`}>Edit</a>
                    </Link>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className='align-center col-span-1 flex w-full items-center py-3 text-center'
            role='columnheader'
          >
            <span className='flex h-fit w-fit overflow-hidden text-ellipsis text-center font-display text-jacarta-700 dark:text-jacarta-100'>
              <ChainIcon name={chain} tooltip={getTokenName(chain)} width={18} />
              <div className='ml-1'>{!floorPrice || floorPrice === 0 ? '-' : floor}</div>
            </span>
          </div>
          <div
            className='align-center col-span-1 flex w-full items-center py-3 text-center'
            role='columnheader'
          >
            <span className='flex h-fit w-fit overflow-hidden text-ellipsis text-center font-display text-jacarta-700 dark:text-jacarta-100'>
              <ChainIcon name={chain} tooltip={getTokenName(chain)} width={18} />
              <Tooltip title={volumeTraded}>
                <span className='text-dark ml-1 text-sm'>
                  {!volume || volume == 0
                    ? '0'
                    : volume > 1
                    ? parseFloat(volume).toFixed(2)
                    : parseFloat(volume).toFixed(4)}
                </span>
              </Tooltip>
            </span>
          </div>
          <div
            className='align-center col-span-1 hidden w-full items-center py-3 text-center md:flex'
            role='columnheader'
          >
            <span className='h-fit w-fit overflow-hidden text-ellipsis text-center font-display text-jacarta-700 dark:text-jacarta-100'>
              {ownerCount}
            </span>
          </div>
          <div
            className='align-center col-span-1 hidden w-full  items-center py-3 text-center md:flex'
            role='columnheader'
          >
            <Tooltip title={`Total Items: ${totalItems}`}>
              <span className='h-fit w-fit overflow-hidden text-ellipsis text-center font-display text-jacarta-700 dark:text-jacarta-100'>
                {itemCount}
              </span>
            </Tooltip>
          </div>
          <div
            className='align-center col-span-1 flex  w-full items-center justify-center py-3 text-center'
            role='columnheader'
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <WatchListButton collectionAddress={address} />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export const Explore_collection_item_table = ({ collectionData }) => {
  return (
    <div className='w-full overflow-auto'>
      <div className='w-full min-w-[550px] rounded-lg border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white md:min-w-[850px]'>
        <div
          className='grid grid-cols-7 rounded-t-lg border-b border-jacarta-100 bg-jacarta-50 dark:border-jacarta-600 dark:bg-jacarta-600 md:grid-cols-12'
          role='row'
        >
          <div className='col-span-1 py-3 px-4' role='columnheader'>
            <span className='flex w-full justify-center overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              #
            </span>
          </div>
          <div className='col-span-3 py-3 px-4 text-left md:col-span-6' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-center text-jacarta-700 dark:text-jacarta-100'>
              Collection
            </span>
          </div>
          <div className='col-span-1 py-3' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-left text-jacarta-700 dark:text-jacarta-100'>
              Floor
            </span>
          </div>
          <div className='col-span-1 py-3' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              Volume
            </span>
          </div>
          <div className='col-span-1 hidden py-3 md:block' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              Owners
            </span>
          </div>
          <div className='col-span-1 hidden py-3 md:block' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              Items
            </span>
          </div>
          <div className='col-span-1 overflow-hidden py-3 text-center' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-center text-jacarta-700 dark:text-jacarta-100'>
              WatchList
            </span>
          </div>
        </div>
        {collectionData?.map((item, i) => {
          return <CollectionRow key={item.address} item={item} index={i} />;
        })}
      </div>
    </div>
  );
};

/* <>
      {collectionData?.map((item) => {
        const {
          address,
          coverPhoto,
          profilePhoto,
          headerPhoto,
          name,
          profilePhotoPath,
          pathName,
          itemCount,
          userName,
          coverPhotoPath,
          creatorAddress,
          chain,
        } = item;return (
          <article key={address}>
            <div className='rounded-2xl border border-jacarta-100 bg-white transition-shadow hover:shadow-lg  dark:border-jacarta-700 dark:bg-jacarta-700'>
              <Link href={getCollectionPath(chain, address)}>
                <a className='flex space-x-[0.625rem]'>
                  <span className='w-[100%]'>
                    <ImageFixedAO image={getCdnUrl(profilePhotoPath)} resolution={262} alt={name} />
                  </span>
                </a>
              </Link>

              <div className={'w-full p-[1.1875rem]'}>
                <div className='flex w-full items-center justify-between space-x-1'>
                  <Link href={getCollectionPath(chain, address)}>
                    <a className='contents font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent'>
                      {name}
                    </a>
                  </Link>
                  <ChainIcon name={chain} tooltip={getChainName(chain)} width={18} type={'chain'} />
                </div>

                <div className='mt-2 flex items-center justify-between text-sm font-medium tracking-tight'>
                  <span className='text-sm dark:text-jacarta-300'>{itemCount} Items</span>
                  <div className='flex flex-wrap items-center'>
                    {creatorAddress === walletAddress && (
                      <Link href={'/collection/edit/' + address}>
                        <a className='text-accent' target='_blank'>
                          <span>Edit</span>
                        </a>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </article>
        );
      })}
    </>
  );*/
