import React, { useEffect, useState } from 'react';
import Dropdown_dynamic from '../dropdown/dropdown_dynamic';
import { JsonStringify } from '../_debug/json.stringify';
import { SearchInput } from '../SearchInput/search_input';
import Horizontal_fitler_contantainer from './horizontal_fitler_contantainer';
import DropDownItemNetworks from '../dropdown/DropDownItemNetworks';
import { VerticalFilterCollapsable } from './collapsable';
import { Dropdown_container } from '../dropdown/dropdown_container';

export const HorizontalCollectionFilter = ({ filterObject = {}, onChange }) => {
  const handleFitlerEvents = (name, value) => {
    onChange({
      ...filterObject,
      [name]: value,
    });
    console.log('calisti');
  };
  return (
    <Horizontal_fitler_contantainer>
      <SearchInput
        className={'min-w-[200px]'}
        value={filterObject.search}
        onChange={(val) => handleFitlerEvents('search', val)}
      />
      {/*<Dropdown_dynamic
                items={[
                    {
                        id: 1,
                        text: 'All Categories',
                    },
                    {
                        id: 2,
                        text: 'Art',
                    },
                    {
                        id: 3,
                        text: 'Collectables',
                    },
                ]}
                value={filterObject.categories}
                onSelect={(val) => {
                    handleFitlerEvents('categories', val)
                }}
                className={'mt-4 md:mt-1 pr-2 md:pr-0'}
            />*/}

      <Dropdown_container
        name={'Chains'}
        className={'mt-4 ml-auto md:mr-2 md:mt-1 md:pl-2 md:pl-0'}
        isCollectionPage={true}
      >
        <DropDownItemNetworks
          selectedChainIds={filterObject.chainIds}
          onChange={(val) => handleFitlerEvents('chainIds', val)}
        />
      </Dropdown_container>
      <Dropdown_dynamic
        items={[
          {
            id: 'order:asc',
            text: 'Default',
          },
          {
            id: 'updatedAt:desc',
            text: 'Recent Activity',
          },
          {
            id: 'floor:asc',
            text: 'Floor Low To High',
          },
          {
            id: 'floor:desc',
            text: 'Floor High To Low',
          },
          {
            id: 'volume:asc',
            text: 'Volume Low To High',
          },
          {
            id: 'volume:desc',
            text: 'Volume High To Low',
          },
          {
            id: 'createdAt:desc',
            text: 'Newest',
          },
        ]}
        value={filterObject.sort}
        onSelect={(val) => {
          handleFitlerEvents('sort', val);
        }}
        className={'mt-4 pl-2 md:mt-1 md:pl-0'}
      />
    </Horizontal_fitler_contantainer>
  );
};
